@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-Medium.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-Bold.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-BoldItalic.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-ExtraBoldItalic.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-MediumItalic.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-Regular.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-ExtraBold.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('src/jetbrains-mono/JetBrainsMono-Italic.woff2') format('woff2'),
        url('src/jetbrains-mono/JetBrainsMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

